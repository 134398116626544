import { Link } from "@remix-run/react";

export function NavLink({
                          href,
                          children,
                          rawAnchor
                        }: {
  href: string
  children: React.ReactNode
  rawAnchor?: boolean
}) {

  if (rawAnchor) {
    return (
      <a href={href}
         className="inline-block rounded-lg px-2 py-1 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900">
        {children}
      </a>
    );
  }

  return (
    <Link
      to={href}
      className="inline-block rounded-lg px-2 py-1 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900"
    >
      {children}
    </Link>
  );
}